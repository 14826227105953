import '@glow/common/utils/atPolyfill'
import { isProduction, sentryEnvironment, trackingSentryAPIKey } from '@glow/common/utils/env'
import { initializeSiteAnalytics, initializeSiteMetrics } from '@glow/instrumentation'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'

const ENABLE_ANALYTICS_FOR_THIS_APP = false
if (isProduction() && ENABLE_ANALYTICS_FOR_THIS_APP) {
  initializeSiteAnalytics()
}

const apiKey = trackingSentryAPIKey()
if (apiKey) {
  initializeSiteMetrics(apiKey, sentryEnvironment(), { defaultSampleRate: 0.001 })
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
