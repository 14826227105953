const fi = {
  common: {
    unknown: 'Tuntematon',
    shipmentId: 'Tilaus ID',
    senderRef: 'Lähettäjä viite',
    phone: 'Puhelinnumero:',
    yes: 'Kyllä',
    no: 'Ei',
    cancel: 'Peruuta',
    somethingWentWrongTryAgain: 'Jotain meni pieleen, ole hyvä ja yritä myöhemmin uudelleen.',
    courier: 'Lähetti',
    unsupportedBrowser: 'Päivitä selaimesi versio käyttääksesi kaikkia ominaisuuksia',
    blankText: '(Tyhjä)',
    updatedInformation: 'Päivitetty tieto',
    thanks: 'Kiitos!',
    edit: 'Muokkaa',
    required: 'pakollinen tieto'
  },
  skipToMainContent: 'Siirry pääsisältöön',
  pageNotFound: {
    title: 'Sivua ei löydy',
    message: 'Emme löydä etsimääsi sivua',
    reloadPage: 'Yritä ladata sivu uudelleen'
  },
  application: {
    loading: 'Latautuu, odota hetki.'
  },
  menu: {
    shipmentTracking: 'Missä on toimitukseni?',
    aboutShipment: 'Tietoa toimituksestani',
    shipmentEvents: 'Tapahtumat'
  },
  deliveredShipment: { goodsDelivered: 'Tuotteesi on toimitettu!' },
  deviatedShipment: {
    goodsNotDelivered: 'Tuotteet eivät ole toimitettu',
    lastEvent: 'Viimeisin tapahtuma {{time}}',
    nextStep: {
      headline: 'Mitä tapahtuu seuraavaksi?',
      newAttempt:
        'Pyrimme toimittamaan lähetyksenne seuraavana arki päivänä. Vastaanotatte uuden ilmoituksen kun lähetys on lastattu kuljetusta varten.',
      returnToSender: 'Lähettäjän ohjeistuksien mukaisesti, lähetys palautetaan lähettäjälle.',
      pickupPoint: 'Lähettäjän ohjeistuksien mukaisesti, lähetys palautetaan lähettäjälle.',
      reachOut: 'Olemme teihin yhteydessä sopiaksemme uuden toimitusyrityksen.'
    }
  },
  shipmentTracking: {
    airExpress: {
      collected:
        'Paketit kohteesta {{company}} on noudettu kohteesta {{pickupArea}} ja lähetetään lentoteitse kohteeseen {{deliveryArea}}.',
      collectedAtDeliveryLeg: 'Paketit kohteesta {{company}} on noudettu kohteesta {{arrivalAirportName}}.',
      collectedAtFlightLeg:
        'Paketit kohteesta {{company}} on hyväksytty terminaalissa kohteessa {{departureAirportName}} ja lähetetään lentoteitse kohteeseen {{deliveryArea}}.',
      collectedAtFlightLegWithOnlyFlightLeg:
        'Paketit kohteesta {{company}} on hyväksytty terminaalissa kohteessa {{departureAirportName}}. Paketit lähetetään lentoteitse kohteeseen {{arrivalAirportName}}, josta vastaanottaja noutaa ne.',
      collectedAtFlightLegWithPickup:
        'Paketit kohteesta {{company}} lähetetään lentoteitse kohteeseen {{arrivalAirportName}}, josta vastaanottaja noutaa ne.',
      collectedAtPickupLegWithPickup:
        'Paketit kohteesta {{company}} on noudettu kohteesta {{pickupArea}} ja lähetetään lentoteitse kohteeseen {{arrivalAirportName}}, josta vastaanottaja noutaa ne.'
    },
    map: 'Kartta',
    estimatedDeliveryTime: 'Arvioitu toimitusaika',
    deliveryTimeChanged: 'Arvioitua toimitusaikaasi muutettiin.',
    numberOfStopsLeft_one: '{{count}} toimitusta ennen sinua',
    numberOfStopsLeft_other: '{{count}} toimitusta ennen sinua',
    manyStopsLeft: 'Enemmän kuin {{many}} toimitusta ennen sinua',
    nextStop: 'Olet seuraava toimitus!'
  },
  aboutShipment: {
    plannedDeliveryTimeHeader: 'Arvioitu toimitusaika kohteesta {{customerName}}',
    header: 'Lähetyksesi kohteesta {{customerName}}',
    deliveryTime: 'Toimitusaika',
    timeWindow: 'välissä {{timeWindow}}',
    deliveryHeader: 'Toimitusosoite sekä yhteyshenkilö'
  },
  shipmentEvents: { header: 'Viimeisin muutos kohteesta {{customerName}}' },
  imageAlt: {
    calendarClockIcon: 'Kalenterin kello',
    questionMarkIcon: 'Kysymysmerkki',
    checkIcon: 'Tarkistus nappi',
    goodsOutsideDoor: 'Vara utanför dörr',
    image: 'Kuva'
  },
  error: {
    cannotLoadMap: 'Karttaa ei voitu juuri nyt ladata, pahoittelumme.',
    invalidDeliveryDate: 'Valittu aika ei ole enään saatavilla. Ole hyvä ja päivitä sivu ja kokeile uudelleen.',
    title: 'Jotain meni vikaan!',
    errorClickToReload: 'Klikkaa tästä ladataksesi sivun uudelleen',
    phoneNumber: 'Väärä puhelinnumero! Oikea on +358 12345678.',
    phoneNumberPrefix: 'Sinun täytyy antaa maanumero, esim. +358...'
  },
  event: {
    parcels: 'pakettia',
    outOf: 'määrästä',
    deviationHeadline: {
      'H-12': 'Emme pystyneet toimittamaan lähetystänne',
      'H-10': 'Emme löytäneet osoitettanne',
      'H-13': 'Lähetys tullaan palauttamaan',
      'V-41-K': 'Lähetys on ikävä kyllä myöhässä',
      'V-DB-AB': 'Lähetys on ikävä kyllä myöhässä',
      'H-BL-A': 'Emme päässeet',
      'H-11-Z': 'Emme löytäneet osoitettanne',
      'VI-16-X': 'Emme pystyneet toimittamaan lähetystänne'
    },
    deviation: {
      'H-12': 'Ikävä kyllä, emme saaneet ketään kiinni kohteessa',
      'H-11': 'Lähetys yritettiin toimittaa, mutta vastaanottajaa ei tavoitettu',
      'H-10': 'Emme pystyneet toimittamaan lähetystänne koska osoitetiedot olivat puutteelliset',
      'H-13': 'Koska et halunnut vastaanottaa sitä',
      '7-10': 'Tavaroita ei voitu noutaa puutteellisen osoitteen takia',
      '7-12': 'Lähettäjä ei ollut tavoitettavissä. Tavaroita ei ole noudettu',
      '7-82': 'Tavarat eivät olleet valmiit noudettavaksi',
      'V-41-K': 'Se tullaan laittamaan eteenpäin niin pian kuin mahdollista',
      'V-DB-AB': 'Se tullaan laittamaan eteenpäin niin pian kuin mahdollista',
      'H-07-R': 'Epäilyttävä, tai päihtynyt vastaanottaja',
      'H-07-Z': 'Epäilyttävä, tai päihtynyt vastaanottaja',
      'H-08-Z': 'Alaikäinen henkilö',
      'H-09-Z': 'Henkilöllisyystodistus puuttuu',
      'VI-16-X':
        'Tänään, kaikki ei mennyt niin kuin piti, emmekä pystyneet toimittamaan lähetystänne sovitusti. Pahoittelemme myöhästymistä',
      'H-BL-A': 'Ikävä kyllä emme pystyneet toimittamaan lähetystä pyytämäänne kohteeseen',
      'H-11-Z': 'Tyypillinen syy tähän on se että nimi tai numero puuttui ovesta'
    },
    message: {
      created: 'Luotu',
      delivered: 'Toimitettu',
      collected: 'Noudettu',
      deviated: 'Poikkema',
      'delivery-time-estimated': 'Päivitetty toimitusaika',
      'delivery-time-purchased': 'Uusi toimitusaika ostettu',
      'delivered-manually-overridden': 'Toimitettu',
      'deviated-manually-overridden': 'Poikkema',
      'collected-manually-overridden': 'Noudettu',
      'flex-delivery-ordered': 'Yksinkertaistettu toimitus',
      flexDeliveryOrderedDescription: 'Yksinkertaistettu toimitus tilattu vastaanottajan toimesta',
      'flex-delivery-ordered-by-sender': 'Yksinkertaistettu toimitus tilattu lähettäjän toimesta',
      'flex-delivery-changed': 'Joustava toimitus muutettu',
      'apartment-information-updated': 'Asunnon tiedot päivitetty',
      'service-upgrade-purchased': 'Lisämyynti: Vastaanottajan tilaama kuljetuspalvelu',
      'claim-registered-email-sent': 'Sähköposti viallisista tai puuttuvista tuotteista lähetetty'
    }
  },
  date: { date: 'Päivämäärä', today: 'Tänään', tomorrow: 'Huomenna', yesterday: 'Eilen' },
  serviceUpsales: {
    heading: 'Haluatko, että tavarat toimitetaan sisälle?',
    curbsideServiceInfo:
      'Olet tilannut toimituksen jalkakäytävälle. Napsauttamalla ”Tilaus sisään toimituksella” voit päättää päivästä ja kellonajasta, jolloin haluat tavaroiden toimitettavan sisälle. Lue lisää ehdoistamme, jotka koskevat Sisätilat-lisäpalvelua.',
    orderService: 'Tilaus sisään toimituksella',
    agreeToTerms: 'Jatka maksamaan tai valitse uusi aikaikkuna toimitukselle.',
    noAvailableDeliveryTimesError: 'Jokin meni vikaan aikavälejä haettaessa. Lataa sivu uudelleen.',
    stateProgressedTooFar: 'Sisätoimitus ei ole enää mahdollista, koska toimituksesi on jo lisätty reitille.'
  },
  housingInfo: {
    housingTypeTitle: 'Asutko asunnossa vai talossa?',
    apartment: 'Asunto',
    house: 'Talo',
    selectConfirmationApartment:
      'Kiitos kun helpotat meitä toimittamaan lähetyksen. Varmistakaa <strong>että nimi</strong> on nähtävillä ovessa tai postilaatikossa.',
    selectConfirmationHouse:
      'Kiitos kun helpotat meitä toimittamaan lähetyksen. Varmistakaa <strong>että talon numero</strong> on nähtävillä talossa tai postilaatikossa.',
    apartmentInformation: 'Asunnon tiedot',
    gateCodeOrNameLabel: 'Porttikoodi tai nimi summerissa',
    floorNumber: 'Kerrosnumero',
    elevator: 'Hissi',
    selectOption: 'Valitse vaihtoehto',
    elevatorIsAvailable: 'Kyllä, hissi on käytössä',
    elevatorNotAvailable: 'Ei, hissi ei ole käytössä',
    submitError: 'Ole hyvä, täytä kerrosnumero',
    submit: 'Lähetä',
    submitChange: 'Lähetä Muutokset',
    confirmationText: 'Tämä helpottaa toimitusta.',
    confirmationHeader: 'Kiitos asuntonne tietojen päivittämisestä.'
  },
  flex: {
    header: 'Jätämmekö lähetyksen ovelle?',
    termsApproval: 'Painamalla kyllä hyväksyt meidän <1>termit</1>.',
    privacyPolicy: 'Löydät <1>Käyttöehdot</1> täältä.',
    deliverySpotHeader: 'Mihin toimitamme?',
    deliverySpotDefaultOption: 'Valitse kohta',
    deliverySpotTextHeader: 'Viesti kuljettajalle (max 50 kirjainta)',
    deliverySpotError: 'Valitse mihin tuotteet sijoitetaan',
    confirmationHeader: 'Kiitos, kun päivitit toimitustietosi.',
    confirmationText:
      'Toimitamme tilauksesi sovitusti {{location}}. Ilmoitamme teille kun toimitus on tehty. Kuljettaja saattaa ottaa kuvan toimituksesta.',
    infoText:
      'Toimitamme tilauksesi sovitusti {{location}}. Ilmoitamme teille kun toimitus on tehty. Kuljettaja saattaa ottaa kuvan toimituksesta.',
    notRingDoorBellText: 'Lähetti ei soita ovikelloa.',
    submitChanges: 'Lähetä muutokset',
    submit: 'Lähetä',
    terms: {
      header: 'Käyttöehdot',
      description: 'Toimitus oven ulkopuolelle – Näin se toimii:',
      details1:
        'Asiakkaana, te valitsette mihin me jätämme tuotteenne ja olette vastuussa että paikka on sopiva siihen. Muistakaa että sijainnin täytyy olla turvallinen sekä säänkestävä.',
      details2: 'Vastaanotat tekstiviestin kun lähetys on toimitettu.',
      details3: 'Toimituksen jälkeen, sinä vastaanottajana olet vastuussa lähetyksen vaurioista tai katoamisesta.'
    },
    flexDeliveryCancelledInfo: 'Toimitamme tämän henkilökohtaisesti.'
  },
  digitalIdentity: {
    cta: {
      verify: 'Vahvista henkilöllisyytesi',
      submit: 'Lähetä ja allekirjoita',
      retry: 'Yritä vahvistaa uudelleen',
      info: {
        header: 'Haluatko sujuvamman toimituksen?',
        se: 'Toimituksen sujuvuuden helpottamiseksi voit tunnistautua BankID:llä ennen toimitusajankohtaa',
        no: 'Toimituksen sujuvuuden helpottamiseksi voit tunnistautua BankID:llä ennen toimitusajankohtaa',
        dk: 'Toimituksen sujuvuuden helpottamiseksi voit tunnistautua MitID:llä ennen toimitusajankohtaa'
      },
      flex: {
        header: 'Tunnistus vaaditaan',
        se: 'Sinun tulee tunnistaa henkilöllisyytesi ennen kuin voimme toimittaa toimituksen ulos. Käytä yllä olevaa painiketta vahvistaaksesi henkilöllisyytesi BankID:llä.',
        no: 'Sinun tulee tunnistaa henkilöllisyytesi ennen kuin voimme toimittaa toimituksen ulos. Käytä yllä olevaa painiketta vahvistaaksesi henkilöllisyytesi BankID:llä.',
        dk: 'Sinun tulee tunnistaa henkilöllisyytesi ennen kuin voimme toimittaa toimituksen ulos. Käytä yllä olevaa painiketta vahvistaaksesi henkilöllisyytesi MitID:llä.'
      },
      button: { se: 'Allekirjoita BankID:llä', no: 'Allekirjoita BankID:llä', dk: 'Allekirjoita MitID:llä' }
    },
    verificationError: {
      header: 'Vahvistus epäonnistui',
      message:
        'Voit aina vastaanottaa lähetyksesi henkilökohtaisesti näyttämällä voimassa olevaa henkilötodistusta kuljettajan saapuessa tai voit yrittää suorittaa vahvistuksen vielä kerran'
    },
    verificationSuccess: {
      header: 'Henkilöllisyytesi on vahvistettu',
      saveCodeHeader: 'Henkilöllisyyden vahvistus onnistui!',
      saveCodeText:
        'Tallenna tämä koodi ja ota se mukaasi, kun saat paketin, tai lähetä se noutajalle. Saat tekstiviestin, kun kuriiri on toimitusosoitteen ulkopuolella. Tämä koodi lähetetään myös tekstiviestinä.',
      nextPageVisit:
        'Vahvistuskoodisi on lähetetty sinulle tekstiviestillä, anna koodi kuljettajalle toimituksen yhteydessä.'
    },
    visibleDamageNotice:
      'Varmista, että tavarassa ei ole näkyviä vaurioita ennen toimituksen hyväksymistä. Kuljettajan tulee rekisteröidä näkyvät vauriot.'
  },
  damageInfo: {
    reportDamage: 'Ilmoita vauriosta',
    submit: 'Lähetä',
    unfinishedReport: 'Unfinished Report',
    reportSubmittedPara1:
      'Kiitos kun ilmoitit tilauksessasi olevasta virheestä. IKEA ottaa sinuun yhteyttä 24 tunnin kuluessa.',
    reportSubmittedPara2: 'Palaa aloitussivulle tai luo toinen raportti painamalla Valmis.'
  },
  plannedDelivery: {
    newAttempt: 'Jos et ole paketin vastaanottamisen kotona, yritämme seuraavana työpäivänä uudelleen',
    pickupPoint: 'Jos et ole paketin vastaanottamisen kotona, se toimitetaan paikalliseen noutopisteeseesi',
    returnToSender: 'Jos et ole paketin vastaanottamisen kotona, se palautetaan lähettäjälle'
  },
  timeBooking: { confirm: 'Vahvistaa' },
  paymentFailed: {
    header: 'Maksu epäonnistui',
    details: 'Yritä uudelleen myöhemmin tai soita asiakaspalveluumme johonkin alla olevista puhelinnumeroista.',
    detailsSaas: 'Valitettavasti emme voineet suorittaa maksua. Yritä uudelleen myöhemmin.',
    goBack: 'Palaa seurantasisältösivulle'
  },
  pickupTracking: { estimatedPickupTime: 'Arvioitu noutoaika', goodsPickedUp: 'Lähetys on noudettu!' },
  environmentFriendly: {
    fossilFreeDelivery1: 'Lähetyksesi on ',
    fossilFreeDelivery2: 'fossiili vapaa.',
    zeroEmissionDelivery: 'Lähetyksesi toimitetaan ',
    bicyclePreposition: '',
    carPreposition: '',
    electric: 'sähköauto.',
    bicycle: 'polkupyörä.',
    hydrogen: 'vetyauto.',
    bring: {
      electric: '100% sähkö',
      bicycle: '100% lihaskäyttöinen',
      hydrogen: '100% vety',
      fossilFreeDelivery: '100% fossiilivapaa polttoaine',
      naturally: 'luonnollisesti'
    }
  },
  ikeaDeliveryNote: {
    doYouNeedADeliveryNote: 'Haluatko saapumisilmoituksen IKEA?',
    noProblemDownloadItHere: 'Ei hätää! Voit ladata sen täältä.',
    downloadDeliveryNote: 'Lataa saapumisilmoitus.',
    noProblemViewItHere: 'No problem! You can view and print it here.',
    viewDeliveryNote: 'View delivery note'
  },
  deliveryNote: {
    title: 'Delivery Note',
    sender: 'Sender',
    vatNumber: 'VAT no',
    orderNumber: 'Order number',
    receiver: 'Receiver',
    phone: 'Phone',
    shipmentId: 'Shipment ID',
    articles: 'Articles',
    orderDate: 'Order Date',
    deliveryDate: 'Delivery Date',
    deliveryTime: 'Delivery Time',
    printedDate: 'Printed Date',
    receiversReference: "Receiver's reference",
    receiversReferenceNote: 'Please use this number in all contact with us',
    articleNumber: 'Number',
    articleName: 'Name',
    articlePrice: 'Price',
    articleQuantity: 'Quantity',
    numberOfPackages: 'Number of packages',
    totalWeight: 'Total weight',
    totalVolume: 'Total volume',
    print: 'Print delivery note'
  },
  requestPhoneNumber: {
    header: 'Tekstiviesti-ilmoitus',
    help: 'Lisää puhelinnumerosi jotta saat toimitus ilmoitukset tekstiviestillä.',
    label: 'Sinun puhelinnumerosi',
    save: 'Tallenna numero',
    done: 'Kiitos! Saat jatkossa ilmoitukset tekstiviestillä.'
  },
  vasDescription: { header: 'Lähetyksesi kohteesta' },
  christmas: {
    thankYou: 'Kiitos!',
    wishRegistered: 'Toiveesti on lisätty toivelistalle'
  },
  altText: { previewImage: 'Katso kuva' },
  purchaseReceipt: {
    purchaseOfTimeWindowHeading: 'Uusi toimitusaikasi on tilattu ja vahvistettu',
    serviceUpgradeHeading: 'Toimituksen päivitys on tilattu ja vahvistettu',
    receipt: 'Maksukuitti',
    organisationNumber: 'Kaupparek.nro: 556546-4939',
    orderNumber: 'Tilausnumero',
    date: 'Päivämäärä',
    time: 'Kellonaika',
    service: 'Palvelu',
    purchaseOfTimeWindow: 'Toimituksen aikaikkuna',
    serviceUpgrade: 'Toimitus siätiloihin',
    gross: 'Yhteensä ilman ALV:a',
    vat: 'ALV ({{vat}} %)',
    total: 'Yhteensä'
  },
  parcelLocker: { cannotCancelMessage: 'Choosing delivery to a parcel locker can not be cancelled.' }
}

export default fi
