import { setup } from '@glow/language'
import i18n from 'i18next'
import da from './da'
import en from './en'
import fi from './fi'
import fr from './fr'
import { userLanguage } from './language'
import nb from './nb'
import sv from './sv'

setup(userLanguage())
i18n.addResourceBundle('en', 'translation', en)
i18n.addResourceBundle('sv', 'translation', sv)
i18n.addResourceBundle('nb', 'translation', nb)
i18n.addResourceBundle('da', 'translation', da)
i18n.addResourceBundle('fi', 'translation', fi)
i18n.addResourceBundle('fr', 'translation', fr)

document.documentElement.lang = i18n.language
