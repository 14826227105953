import nb from './nb'
import sv from './sv'

const en = {
  common: {
    unknown: 'Unknown',
    shipmentId: 'Shipment number',
    senderRef: 'Senders reference',
    phone: 'Phone:',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    somethingWentWrongTryAgain: 'Something went wrong. Please try again later.',
    courier: 'Courier',
    done: 'Done',
    unsupportedBrowser: 'Update your browser version to access all features',
    islandDeliveryBanner: nb.common.islandDeliveryBanner,
    blankText: '(blank)',
    updatedInformation: 'Updated information',
    thanks: 'Thanks!',
    edit: 'Edit',
    confirmation: 'Confirmation',
    required: 'required'
  },
  application: {
    loading: 'Application loading'
  },
  feedbackBox: {
    title: 'What do you think',
    description:
      'We are currently conducting a test where you, as a recipient, can track your shipments in real time when they are out for delivery. We are interested in hearing your opinion, <0>please provide us with feedback via this link</0>.'
  },
  skipToMainContent: 'Skip to main content',
  countries: {
    sweden: 'Sweden',
    norway: 'Norway',
    denmark: 'Denmark',
    finland: 'Finland'
  },
  pageNotFound: {
    title: 'Page not found',
    message: "We couldn't find the page you're looking for",
    reloadPage: 'Try to reload the page'
  },
  menu: {
    shipmentTracking: 'Where is my delivery?',
    aboutShipment: 'About my delivery',
    shipmentEvents: 'Events'
  },
  logisticsProvider: {
    deliveredBy: 'Delivered by'
  },
  deliveredShipment: {
    goodsDelivered: 'Your goods are delivered!'
  },
  deviatedShipment: {
    goodsNotDelivered: 'Goods not delivered',
    lastEvent: 'Last event {{time}}',
    nextStep: {
      headline: 'What happens next?',
      newAttempt:
        "We will try to delivery your parcel to you on the next working day. You'll get a new message when it's loaded for delivery.",
      returnToSender: 'According to the instructions from the sender, the parcel will be returned to sender.',
      pickupPoint:
        "The parcel will be delivered to your local pickup point during the next working day. You'll get a new message when it's ready to be picked up.",
      reachOut: "We'll reach out to you to plan a new delivery attempt.",
      homeDelivery: 'We will attempt to deliver the parcel to your home address instead.'
    }
  },
  shipmentTracking: {
    airExpress: {
      collected:
        'The packages from {{company}} have been picked up in {{pickupArea}} and will be sent with a flight to {{deliveryArea}}.',
      collectedAtDeliveryLeg: 'The packages from {{company}} have been picked up at {{arrivalAirportName}}.',
      collectedAtFlightLeg:
        'The packages from {{company}} have been accepted at terminal at {{departureAirportName}} and will be sent with a flight to {{deliveryArea}}.',
      collectedAtFlightLegWithOnlyFlightLeg:
        'The packages from {{company}} have been accepted at terminal at {{departureAirportName}}. Packages will be sent with a flight to {{arrivalAirportName}} to be picked up by the recipient.',
      collectedAtFlightLegWithPickup:
        'The packages from {{company}} will be sent with a flight to {{arrivalAirportName}} to be picked up by the recipient.',
      collectedAtPickupLegWithPickup:
        'The packages from {{company}} have been picked up at {{pickupArea}} and will be sent with a flight to {{arrivalAirportName}} to be picked up by the recipient.'
    },
    showMapButton: 'Follow in live map',
    map: 'Map',
    estimatedDeliveryTime: 'Estimated delivery time',
    deliveryTimeChanged: 'Your estimated delivery time has changed.',
    numberOfStopsLeft_one: '{{count}} stop left before you',
    numberOfStopsLeft_other: '{{count}} stops left before you',
    manyStopsLeft: 'More than {{many}} stops left before you',
    nextStop: "You're the next stop!"
  },
  aboutShipment: {
    delayMessageH2Message: ' Hvis din pakke blir forsinket, så vil vi forsøke å levere igjen neste virkedag.',
    plannedDeliveryTimeHeader: 'Estimated delivery time of your delivery from {{customerName}}',
    header: 'About your delivery from {{customerName}}',
    deliveryTime: 'Delivery time',
    timeWindow: 'between {{timeWindow}}',
    deliveryHeader: 'Delivery address and contact information'
  },
  shipmentEvents: {
    header: 'The latest about your delivery from {{customerName}}'
  },
  imageAlt: {
    calendarClockIcon: 'Calender clock',
    questionMarkIcon: 'Question mark',
    checkIcon: 'Check mark',
    pageNotFound: '404',
    signature: 'Signature',
    thumbsUp: 'Thumbs up',
    sadComputer: 'Sad computer',
    goodsOutsideDoor: 'Goods outside door',
    idCard: 'ID Card',
    image: 'Image'
  },
  error: {
    cannotLoadMap: 'Sorry, the map cannot be loaded right now.',
    invalidDeliveryDate: 'The selected time is no longer available. Please reload the page and try again.',
    title: 'Something went wrong!',
    errorClickToReload: 'Click here to reload the page',
    phoneNumber: 'Invalid phone number. Valid: +47 12345678.',
    phoneNumberPrefix: 'You must provide a country code, e.g. +47 ...',
    CONSIGNMENT_ALREADY_ASSIGNED_TO_SLOT: 'Something went wrong, please refresh your page.',
    INVALID_COUNTRY_CODE:
      'Some information missing about your delivery, if you still want it to be delivered in a parcel box, please contact customer care.',
    INVALID_LOCATION:
      'Some information missing about your delivery, if you still want it to be delivered in a parcel box, please contact customer care.',
    INVALID_DELIVERY_CONTACT_INFO:
      'Some information missing about your delivery, if you still want it to be delivered in a parcel box, please contact customer care.',
    INVALID_DELIVERY_PHONE:
      'Some information missing about your delivery, if you still want it to be delivered in a parcel box, please contact customer care.',
    CANNOT_ASSIGN_PARCEL_LOCKER: 'Could not select this parcel box, please choose another one.',
    CANNOT_CANCEL_PARCEL_LOCKER_DELIVERY: 'Something went wrong, please try again later.'
  },
  event: {
    parcels: 'parcels',
    outOf: 'out of',
    deviationHeadline: {
      'H-12': 'We could not deliver your shipment',
      'H-10': 'We could not find your address',
      'H-13': 'The shipment will be returned',
      'V-41-K': 'The shipment is unfortunately delayed',
      'V-DB-AB': 'The shipment is unfortunately delayed',
      'H-BL-A': 'We could not reach the agreed place of delivery',
      'H-11-Z': 'We could not find your address',
      'VI-16-X': 'We did not manage to deliver your shipment',
      'V-100-CA': 'We can not deliver to a parcelbox',
      'V-100-CI': 'We could not deliver to a parcelbox',
      'V-80-CI': 'We could not deliver to a parcelbox',
      'V-99-CI': 'We could not deliver to a parcelbox',
      'V-AO-CI': 'We could not deliver to a parcelbox'
    },
    deviation: {
      'H-12': 'Unfortunately, we did not meet anyone at home who could receive it',
      'H-11': 'An attempt has been made to deliver the goods, but the recipient was not found',
      'H-10': 'We could not deliver your shipment because we were missing information about your address',
      'H-13': 'Because you did not want to receive it',
      '7-10': 'Goods could not be picked up, due to incomplete address',
      '7-12': 'The sender was not available. Goods has not been picked up',
      '7-82': 'Goods was not ready for pickup',
      'V-41-K': 'It will be forwarded as soon as possible',
      'V-DB-AB': 'It will be forwarded as soon as possible',
      'H-07-R': 'Failed social control',
      'H-07-Z': 'Failed social control',
      'H-08-Z': 'Failed age control',
      'H-09-Z': 'Failed ID control',
      'VI-16-X':
        'All did not go according to plan today, and we did not manage to deliver your shipment as agreed. We apologize for the delay',
      'H-BL-A': 'Therefore we unfortunately were not able to deliver your shipment where you requested us to',
      'H-11-Z': 'A typical reason for this is that the name or number was missing at the door',
      'V-100-CA': 'There are no available parcelboxes in your area',
      'V-100-CI': 'No available hatch',
      'V-80-CI': 'The package exceeding the permissible dimensions/weight',
      'V-99-CI': 'Other Unknown',
      'V-AO-CI': 'The machine is out of order'
    },
    message: {
      created: 'Created',
      delivered: 'Delivered',
      collected: 'Collected',
      deviated: 'Deviated',
      'delivery-time-estimated': 'Updated delivery time',
      'delivery-time-purchased': 'Upsales: Shorter time window ordered by recipient',
      'delivered-manually-overridden': 'Delivered',
      'deviated-manually-overridden': 'Deviated',
      'collected-manually-overridden': 'Collected',
      'flex-delivery-ordered': 'Simplified delivery',
      flexDeliveryOrderedDescription: 'Simplified delivery ordered by receiver',
      'flex-delivery-ordered-by-sender': 'Simplified delivery ordered by sender',
      'flex-delivery-changed': 'Simplified delivery changed',
      'reported-damaged': 'Reported Damage',
      'reported-missing-article': 'Reported missing article(s)',
      'apartment-information-updated': 'Apartment information updated',
      'service-upgrade-purchased': 'Upsales: Carry-in service ordered by recipient',
      'claim-registered-email-sent': 'Claim registered e-mail sent',
      'parcel-locker-delivery-selected': 'Parcel box selected',
      'cancelled-parcel-locker-delivery': 'Parcel box cancelled'
    }
  },
  date: {
    date: 'Date',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
    from: 'From',
    fromDate: 'From date',
    rangeOptions: {
      '15d': '+/- 15 days',
      '30d': '+/- 30 days',
      custom: 'Custom dates',
      last30d: 'Last 30 days',
      last7d: 'Last 7 days',
      month: 'Month',
      today: 'Today',
      tomorrow: 'Tomorrow',
      yesterday: 'Yesterday'
    },
    to: 'To',
    toDate: 'To date'
  },
  serviceUpsales: {
    heading: 'Do you wish to have the goods carried in?',
    selectDateTime: 'Select date and time for indoor delivery',
    confirm: 'Go to checkout',
    curbsideServiceInfo: 'With indoor delivery, we deliver the goods to a location selected by you.',
    orderService: 'Select indoor timeslot (from 399,-)',
    agreeToTerms: 'By ordering, you agree to the <0>terms of indoor delivery</0>.',
    noAvailableDeliveryTimesError: 'Something went wrong when fetching the time slots. Please reload the page.',
    termsLink: 'See <0>terms for indoor delivery</0>.',
    terms: sv.serviceUpsales.terms,
    stateProgressedTooFar: 'Indoor delivery is no longer possible since your delivery has already been added to a route.'
  },
  housingInfo: {
    housingTypeTitle: 'Do you live in an apartment or house?',
    apartment: 'Apartment',
    house: 'House',
    selectConfirmationApartment:
      'Thank you for helping us to facilitate the delivery. Make sure <strong>your name</strong> is visible on the door/mailbox.',
    selectConfirmationHouse:
      'Thank you for helping us to facilitate the delivery. Make sure <strong>your house number</strong> is visible on the house/mailbox.',
    apartmentInformation: 'Apartment information',
    gateCodeOrNameLabel: 'Gate code or name on intercom',
    floorNumber: 'Floor number',
    elevator: 'Elevator',
    selectOption: 'Select option',
    elevatorIsAvailable: 'Yes, elevator is available',
    elevatorNotAvailable: 'No, elevator is not available',
    submitError: 'Please fill in a floor number',
    submit: 'Submit',
    submitChange: 'Submit Changes',
    confirmationText: 'This will help facilitate the delivery.',
    confirmationHeader: 'Thank you for updating your apartment information.'
  },
  flex: {
    header: 'Should we place the delivery outside your door?',
    termsApproval: 'By selecting Yes you accept our <1>terms</1>.',
    privacyPolicy: 'You can find our <1>privacy policy</1> here.',
    deliverySpotHeader: 'Where should we put it?',
    deliverySpotDefaultOption: 'Choose a spot',
    deliverySpotTextHeader: 'Message to the driver (max 50 characters)',
    deliverySpotError: 'Choose where we should place the goods',
    confirmationHeader: 'Thank you for updating your delivery information.',
    confirmationText:
      "We'll place your goods {{location}} and notify you when the delivery is done. The driver might take a photo upon delivery.",
    infoText:
      "As agreed, we'll place your goods {{location}}. We will notify you when the delivery is done. The driver might take a photo upon delivery.",
    notRingDoorBellText: 'The courier will not ring the doorbell.',
    submitChanges: 'Submit changes',
    submit: 'Submit',
    terms: {
      header: 'Terms and conditions',
      description: 'Delivery outside the door - this is how it works:',
      details1:
        'As the recipient, you choose where we will place the goods and are responsible for ensuring that it is a suitable place. Remember that the delivery location must be both safe and weatherproof.',
      details2: 'You will receive an SMS confirming that the delivery has been made.',
      details3: 'After delivery, you as the recipient are responsible for any damages or losses.'
    },
    flexDeliveryCancelledInfo: 'We will hand it to you in person.'
  },
  digitalIdentity: {
    cta: {
      verify: 'Verify your identity',
      submit: 'Submit and sign',
      retry: 'Retry verification',
      info: {
        header: 'Do you want to have a smoother delivery?',
        se: 'To make the delivery smoother you can identify yourself with BankID before the time of delivery',
        no: 'To make the delivery smoother you can identify yourself with BankID before the time of delivery',
        dk: 'To make the delivery smoother you can identify yourself with MitID before the time of delivery'
      },
      flex: {
        header: 'Identification required',
        se: 'You need to identify yourself before we can place the delivery outside. Use the button above to verify your identity with BankID.',
        no: 'You need to identify yourself before we can place the delivery outside. Use the button above to verify your identity with BankID.',
        dk: 'You need to identify yourself before we can place the delivery outside. Use the button above to verify your identity with MitID.'
      },
      button: {
        se: 'Sign with BankID',
        no: 'Sign with BankID',
        dk: 'Sign with MitID'
      }
    },
    verificationError: {
      header: 'Verification Failed',
      message:
        'You can always receive your shipment in person by showing a valid identification document when the driver arrives, or you could retry the verification'
    },
    verificationSuccess: {
      header: 'Your identity is verified',
      saveCodeHeader: 'Identity verification was successfully done!',
      saveCodeText: 'Give the code to the driver upon delivery, the code has also been sent to you as an sms.',
      nextPageVisit: 'Your verification code has been sent to you by sms, give the code to the driver upon delivery.'
    },
    visibleDamageNotice:
      'Make sure to check the goods for visible damanges before approving the delivery. Visible damages should be registred by the driver.'
  },
  damageInfo: {
    checkYourGoods: 'Make sure to check your goods!',
    checkYourGoodsDescription: 'Anything missing or that’s damaged? Click below to make a report',
    createReport: 'Create report',
    reportDamage: 'Report damage',
    reportMissingArticle: 'Report missing article',
    reportMissingArticleOrDamage: 'Report missing article or damages on goods',
    describeDamage: 'Describe the damage and write the article number',
    describeDamagePlaceholder: 'Ex. There’s a damage on the edges of the cabinets. IKEA Article no: 1245688',
    uploadPhotoDamaged: 'Upload photo of the damaged goods',
    uploadPhotoDamagedText: 'Click here or drag and drop',
    uploadPhotoDamagedDescription: 'You can upload maximum four images',
    uploadMaximumFileSizeLimit: 'File size cannot be greater than {{size}} KB',
    uploadTotalSizeOfAllPhotosLimit:
      'Total size of all photos cannot be greater than {{size}} KB. You can divide your report into two if pictures are too big.',
    uploadAllowedFormats: 'Allowed formats are {{formats}}',
    submit: 'Submit',
    unfinishedReport: 'Unfinished report',
    doYouWishToContinue: 'Changes you have made will not be saved. Do you wish to continue?',
    stayOnPage: 'Stay On Page',
    continueWithoutSubmitting: 'Continue Without Submitting',
    missingDescription: 'You must provide description',
    describeMissingArticle: 'Write down the article(s) that’s missing',
    describeMissingArticlePlaceholder: 'Ex. IKEA Article number: 1245688',
    articleDescriptionError: 'You must provide article number',
    confirmation: 'Confirmation',
    reportSubmittedPara1:
      'Thank you for taking the time to register a discrepancy on your order. IKEA will contact you within 24 hours.',
    reportSubmittedPara2: 'To go back to the start page, or to create another report, press done.',
    minimumFileSizeLimit: 'You must provide at least one file'
  },
  plannedDelivery: {
    headline: {
      delivery: 'Planned delivery time of your delivery from {{customer}}',
      pickupPoint: 'Your delivery from {{customer}} is booked for pickup',
      pickup: 'Your delivery from {{customer}} is now ready for pick-up. Scheduled pick up time',
      return: 'Planned pick up time of your return from {{customer}}'
    },
    deliveryTimeInfo: "When we begin to deliver, you'll get a more exact estimate of one hour.",
    notAGoodTime: 'Not a good time?',
    changeTime: 'Change date and time',
    newAttempt: 'If you are not home to receive the parcel, we will try again the following working day',
    pickupPoint: 'If you are not home to receive the parcel, it will be delivered to your local pickup point',
    returnToSender: 'If you are not home to receive the parcel, it will be returned to the sender',
    delay: {
      headline: 'Leveransen din er forsinket.',
      text: ' Vi forsøker å levere den til deg neste virkedag'
    },
    notAvailable: {
      hd: {
        headline: 'Something seem to have gone wrong!',
        text: 'Please call our Contact Center to book a new time for your delivery, see phone numbers below.'
      },
      nonHd: {
        headline: 'Vi har dessverre ikke et leveringsestimat ennå',
        text: ', men du får en melding når pakken er lastet på bil for utkjøring og du kan sjekke denne siden igjen ved en senere anledning for mer informasjon.'
      }
    }
  },
  timeBooking: {
    confirm: 'Confirm',
    selectDeliveryTime: 'Select delivery time',
    header: 'Pick a date and time',
    price: 'price {{price}}',
    deliveryTime: {
      delivery: 'Delivery time',
      pickup: 'Pick up time',
      return: 'Pick up time'
    },
    submit: {
      delivery: 'Submit delivery time',
      pickup: 'Confirm pick up time',
      return: 'Confirm pick up time'
    },
    confirmation: {
      header: 'Confirmation',
      ready: "You're all set!",
      pickupDetails: 'Time booked for pickup',
      details: {
        delivery: "Lean back and we'll take care of the rest. We'll be there:",
        pickupPoint: 'Time booked for pickup',
        pickup: "Lean back and we'll take care of the rest. You are welcome to us:",
        return: "Lean back and we'll take care of the rest. We'll be there:"
      },
      edit: 'Change'
    }
  },
  bookingNotPossible: {
    header: 'Booking not possible',
    details: {
      delivery:
        "Sorry, it's not possible to book a delivery time online right now. Please try again later or call our Contact Center on one of the phone numbers below.",
      pickup:
        'Pick up time can’t be booked online right now. Please try again in a moment or call our Contact Center at one of the numbers below.',
      return:
        'Pick up time can’t be booked online right now. Please try again in a moment or call our Contact Center at one of the numbers below.'
    },
    'details-saas': {
      delivery: "Sorry, it's not possible to book a delivery time online right now. Please try again later",
      pickup: 'Pick up time can’t be booked online right now. Please try again in a moment.',
      return: 'Pick up time can’t be booked online right now. Please try again in a moment.'
    }
  },
  paymentFailed: {
    header: 'Payment failed',
    details: 'Please try again later or call our Contact Center on one of the phone numbers below.',
    detailsSaas: "Unfortunately, we couldn't complete the payment. Please try again later.",
    goBack: 'Go back to tracking page'
  },
  contact: {
    questions: 'If you have questions',
    callUs: 'you can call our Contact Center.',
    contactUs: 'you can contact us at '
  },
  pickupTracking: {
    estimatedPickupTime: 'Estimated pickup time',
    goodsPickedUp: 'The goods have been picked up!'
  },
  environmentFriendly: {
    fossilFreeDelivery1: 'Your delivery is ',
    fossilFreeDelivery2: 'fossil free.',
    zeroEmissionDelivery: 'Your delivery is made ',
    bicyclePreposition: 'on ',
    carPreposition: 'with ',
    electric: 'an electrical car.',
    bicycle: 'a bicycle.',
    hydrogen: 'a hydrogen car.',
    bring: {
      electric: '100% electric',
      bicycle: '100% muscle-powered',
      hydrogen: '100% hydrogen',
      fossilFreeDelivery: '100% fossil-free fuel',
      naturally: 'naturally'
    }
  },
  ikeaDeliveryNote: {
    doYouNeedADeliveryNote: 'Need a delivery note from IKEA?',
    noProblemDownloadItHere: 'No problem! You can download it here.',
    downloadDeliveryNote: 'Download delivery note',
    noProblemViewItHere: 'No problem! You can view and print it here.',
    viewDeliveryNote: 'View delivery note'
  },
  deliveryNote: {
    title: 'Delivery Note',
    vatNumber: 'VAT no',
    orderNumber: 'Order number',
    sender: 'Sender',
    receiver: 'Receiver',
    phone: 'Phone',
    shipmentId: 'Shipment ID',
    articles: 'Articles',
    orderDate: 'Order Date',
    deliveryDate: 'Delivery Date',
    deliveryTime: 'Delivery Time',
    printedDate: 'Printed Date',
    receiversReference: "Receiver's reference",
    receiversReferenceNote: 'Please use this number in all contact with us',
    articleNumber: 'Number',
    articleName: 'Name',
    articlePrice: 'Price per unit',
    articleQuantity: 'Quantity',
    numberOfPackages: 'Number of packages',
    totalWeight: 'Total weight',
    totalVolume: 'Total volume',
    print: 'Print delivery note'
  },
  timewindowpurchase: {
    terms: {
      conditionsTitle: 'Villkor tilläggstjänst Kort tidsfönster',
      conditionsIntro:
        'Vid beställning av kortare tidsfönster för leverans ("<strong>Kort tidsfönster</strong>"), inom ramen för det ursprungliga breda tidsfönstret, ingår du som beställare avtal med <strong>Bring E-commerce & Logistics AB</strong>, org nr 556546-4939 ("<strong>Bring</strong>") och accepterar följande villkor.',
      bulletOne:
        'Beställning innebär att Bring åtar sig att leverera inom köpt Kort tidsfönster och att Bring i samband därmed ges rätt att hantera nödvändiga personuppgifter.',
      bulletTwo:
        'Betalning ska ske via Swish eller med kort. Transaktionen mellan beställaren och Bring hanteras via betalningsleverantören Adyen.',
      bulletThree: 'Genom sin betalning anses beställaren ha accepterat dessa villkor.',
      bulletFour: 'Lagd beställning är bindande och kan inte ångras.',
      delivery: {
        title: 'Leveransutförande',
        intro:
          'Tilläggstjänsten "<strong>Kort tidsfönster</strong>" innebär att leverans sker inom det tidsfönster beställaren valt. Vid leverans gäller samma förutsättningar som ursprunglig leverans gällande servicenivå, tex ID-kontroll.'
      },
      deviation: {
        title: 'Avvikelser och begränsningar',
        intro:
          'Om Bring misslyckats med att leverera inom köpt tidsfönster återbetalats erlagd avgift via betalningsleverantören. Om mottagaren inte är tillgänglig vid leverans så är det korta tidsfönstret förbrukat och ett eventuellt nytt leveransförsök sker inom ordinarie tidsfönster.'
      },
      miscellaneous: {
        title: 'Övrigt',
        paraOne: 'I övrigt hänvisas till bestämmelserna i NSAB2015, se <0>bring.se/villkor</0>.',
        paraTwo:
          'För eventuella frågor vänligen kontakta Brings kundservice på tel 08 120 245 24 eller via email till <0>operationsteam.sverige@bring.com</0>.'
      }
    },
    headline: 'Do you want a more exact delivery time?',
    toPayment: 'Go to payment',
    approve: 'I approve',
    termsLink: 'terms',
    selectTimeWindow: 'Select time window',
    selectTimeWindowError: 'You need to select a time window',
    paymentInProgress: "Your order couldn't be updated right now due to an ongoing payment.",
    stateProgressedTooFar:
      'Selecting a shorter time window is no longer possible since your delivery has already been added to a route.',
    generalError: 'Unable to proceed with the payment. Please try again later.'
  },
  requestPhoneNumber: {
    header: 'SMS notifications',
    help: 'Add your number to get delivery notifications by SMS.',
    label: 'Your mobile phone number',
    save: 'Save number',
    done: 'Thank you for providing us with a phone number! You will now get delivery notifications by SMS.'
  },
  vasDescription: {
    header: 'About your delivery'
  },
  christmas: {
    title: 'Spread warmth in your everyday life',
    description: 'We will gladly deliver to the nearest parcel box, pickup point, or directly to your home.',
    thankYou: 'Thank you!',
    wishRegistered: 'Your wish has been added to the wishlist',
    seeMoreLink: "What's your christmas wish?"
  },
  altText: {
    previewImage: 'Preview image'
  },
  purchaseReceipt: {
    purchaseOfTimeWindowHeading: 'Your new delivery time is ordered and confirmed',
    serviceUpgradeHeading: 'Update of delivery is ordered and confirmed',
    receipt: 'Receipt',
    companyName: 'Bring E-commerce & Logistics AB',
    organisationNumber: 'Org.no.: 556546-4939',
    orderNumber: 'Order number',
    date: 'Date',
    time: 'Time',
    service: 'Service',
    purchaseOfTimeWindow: 'Delivery time window',
    serviceUpgrade: 'Indoor delivery',
    gross: 'Total excl. VAT',
    vat: 'VAT ({{vat}}%)',
    total: 'Total'
  },
  parcelLocker: {
    heading: 'Should we deliver your package in a parcel box?',
    additionalInfo: 'Select Yes if you prefer that we deliver your package in a parcelbox near you.',
    additionalInfoWithParcelLockerSelected: 'Select which parcel box you want us to leave the package in.',
    pickupLocation: 'Pickup location',
    selectParcelBox: 'Select parcel box',
    selectParcelBoxValidationError: 'Please select a parcel box',
    sendIn: 'Send in',
    deliverPackageTo: 'We will deliver your package in',
    successAdditionalInfo:
      'If you want to change parcel box or revert back to your home address, we advice you to do so as soon as possible.',
    deliveredText: 'Your package has been delivered in a parcel box',
    bannerHeading: 'Important information',
    bannerDescription: 'Please go to the Posten app to open your selected parcel box.',
    futureDeliveryText: 'Your package will be delivered in a parcel box.',
    deliveryOrderedByReceiver: 'Parcel box delivery ordered by receiver',
    nextStop: 'Your parcel box is the next stop',
    manyStopsLeft: 'More than {{many}} stops left before we deliver your package in the parcel box.',
    numberOfStopsLeft: '{{count}} stop before we deliver your package in the parcel box.',
    numberOfStopsLeft_plural: '{{count}} stops before we deliver your package in the parcel box.',
    cannotCancelMessage: 'Selecting delivery in a parcel box can not be cancelled.'
  },
  cancelDeliveryTime: {
    cancelButton: 'Cancel Delivery Time Window',
    preCancellationInfoText:
      'If this time window doesn’t fit, you can cancel the delivery time and we will come back to you with a new delivery time window.',
    postCancellationConfirmationText:
      "You cancelled the suggested delivery time. Lean back and we'll contact you with a new time slot."
  },
  pickupPoint: {
    heading: 'Pickup address',
    termsLink: '<0>See opening hours</0>'
  },
  recycle: {
    section: {
      registeredReturn: 'Registered returns',
      description: 'We have received your waste return orders. The following products are registered for return:',
      withRefrigerant: '{{count}} x with refrigerant',
      withoutRefrigerant: '{{count}} x without refrigerant',
      nothingToReturn: 'You have chosen not to return any products'
    },
    landingPage: {
      heading: 'Hi {{recipientName}}!',
      para1: 'We have received a recycling assignment from {{senderName}}.',
      para2:
        'In order for the take-back to take place as smoothly as possible, we ask you to fill in this form, which is a legal requirement according to the Waste Ordinance (2020:614)',
      para3:
        'Your data is saved and sent to the Swedish Environmental Protection Agency and to {{senderName}}. Here you can read more about our <0>privacy policy</0>.',
      para4:
        'It takes about 2 minutes to fill in the form, then you can book an appointment for delivery and collection.',
      goToForm: 'Go to form',
      nothingToRecycle: 'I have nothing to recycle'
    },
    register: {
      goBack: 'Go back',
      nextStep: 'Next step',
      heading: 'Register waste',
      largeFridgeFreezer: 'Large fridge or freezer',
      smallFridgeFreezer: 'Small fridge or freezer',
      oven: 'Oven',
      dishwasher: 'Dishwasher',
      washingMachine: 'Washing machine',
      dryer: 'Dryer',
      tv: 'TV',
      otherElectronics: 'Other electronics',
      modal: {
        add: 'Add',
        update: 'Update',
        description: {
          largeFridgeFreezer: 'You are about to return a large fridge or freezer. Click Add to continue.',
          smallFridgeFreezer: 'You are about to return a small fridge or freezer. Click Add to continue.',
          oven: 'You are about to return an oven. Click Add to continue.',
          dishwasher: 'You are about to return a dishwasher. Click Add to continue.',
          washingMachine: 'You are about to return a washing machine. Click Add to continue.',
          dryer: 'You are about to return a dryer. Click Add to continue.',
          tv: 'You are about to return a TV. Click Add to continue.',
          otherElectronics:
            'You are about to return other electronics. Tell us more about the product you want to return.'
        },
        productContainRefrigerant: 'Does the product contain refrigerant?',
        productContainRefrigerantTooltip:
          'Refrigerant is when the product contains a cooling unit, eg air conditioning.',
        productWeightPlaceholder: 'What does the product weigh?',
        registerMoreProducts: 'Register more products',
        enterApproximatedWeight: 'Enter approximate weight (kg)',
        weightNotInRange: 'The weight must be between {{from}}kg and {{to}}kg',
        changeAmount: 'Change amount',
        deleteItem: 'Delete item',
        increaseAmount: 'Increase amount',
        decreaseAmount: 'Decrease amount'
      },
      maximumItemsLimitReached: 'Maximum limit of {{count}} products reached.'
    },
    summary: {
      heading: 'Summary',
      para1: 'Soon done! Now we have the information we need. Check the details below and submit.',
      productsToRecycle: 'Type of product I want to recycle',
      para2:
        'By continuing, you accept our terms of service. Keep in mind that the goods you have registered need to be fully disconnected and ready for collection when we arrive.',
      para3: 'Follow the link if you want <0>help with installation and uninstallation.</0>',
      acceptTermsDescription:
        'I accept the terms and conditions and confirm that the information I filled in is correct.',
      goBack: 'Go back',
      send: 'Send'
    }
  }
}

export default en
