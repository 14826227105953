import { getApiPath } from './api'

const is4xxStatus = (status: number) => status.toString().startsWith('4')

const apiPath = getApiPath()

export function query(
  query: string,
  params: any,
  throwError: boolean = false,
  mockRequestResponse?: any,
  shouldReject4xxErrors?: boolean
): Promise<any | Response> {
  if (mockRequestResponse) {
    return new Promise((resolve) => resolve(mockRequestResponse))
  }
  return fetch(`${apiPath}/openquery/${query}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      params: params
    })
  })
    .then((r) => {
      if (r.ok) {
        return r.json()
      } else if (is4xxStatus(r.status)) {
        return shouldReject4xxErrors ? Promise.reject(r) : Promise.resolve()
      } else {
        console.error('Tracking fetch failed in then', `${apiPath}/openquery/${query}`, JSON.stringify(params))
        if (throwError) {
          return Promise.reject(r)
        }
      }
    })
    .catch((error) => {
      console.error(
        'Tracking fetch failed in catch',
        `${apiPath}/openquery/${query}`,
        JSON.stringify(params),
        JSON.stringify(error)
      )
      if (throwError) {
        return Promise.reject(error)
      }
    })
}
